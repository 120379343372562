import ReactOnRails from 'react-on-rails';
import ReactDOM from 'react-dom';

import CheckTotp from '@/pages/totp/Check';

// Must register components to work in the browser
ReactOnRails.register({
  CheckTotp,
});

window.ReactDOM = ReactDOM;
